import { useEffect, useState, useRef } from "react";
import { supabase } from "../../supabaseClient";
import "./PositionTable.css";

const PositionTable = ({
  accountId,
  isDemo,
  onPortfolioValueSumChange,
  onHighValueSumChange,
}) => {
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const hasFetched = useRef(false); // Prevent multiple fetches

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        if (isDemo) {
          // Set demo positions for non-auth'd users
          const demoPositions = [
            {
              symbol: "SPY",
              quantity: 100,
            },
            {
              symbol: "NVDA",
              quantity: 50,
            },
            {
              symbol: "TSLA",
              quantity: 25,
            },
            {
              symbol: "AAPL",
              quantity: 25,
            },
          ];

          // Invoke the getPositionData function with the demo positions
          const { data, error } = await supabase.functions.invoke(
            "getPositionData",
            {
              body: JSON.stringify(demoPositions),
            }
          );

          if (error) throw error;

          const parsedData = JSON.parse(data);
          setPositions(parsedData);

          const totalValue = parsedData.reduce(
            (acc, position) => acc + position.value,
            0
          );
          const totalHighValue = parsedData.reduce(
            (acc, position) => acc + position.highValue,
            0
          );

          onPortfolioValueSumChange(totalValue);
          onHighValueSumChange(totalHighValue);
        } else {
          // Fetch positions from Supabase DB for auth'd users
          const {
            data: positionsData,
            error: positionsError,
          } = await supabase
            .from("positions")
            .select("*")
            .eq("account_id", accountId);

          if (positionsError) throw positionsError;

          if (!Array.isArray(positionsData) || positionsData.length === 0) {
            setPositions([]); // Handle empty positions
            setLoading(false);
            return;
          }

          // Invoke the getPositionData function with the fetched positions
          const { data, error } = await supabase.functions.invoke(
            "getPositionData",
            {
              body: JSON.stringify(positionsData),
            }
          );

          if (error) throw error;

          const parsedData = JSON.parse(data);
          setPositions(parsedData);

          const totalValue = parsedData.reduce(
            (acc, position) => acc + position.value,
            0
          );
          const totalHighValue = parsedData.reduce(
            (acc, position) => acc + position.highValue,
            0
          );

          onPortfolioValueSumChange(totalValue);
          onHighValueSumChange(totalHighValue);
        }
      } catch (error) {
        console.error("Error fetching positions:", error);
        setError(error.message);
        setPositions([]); // Ensure state is an array even on error
      } finally {
        setLoading(false); // Stop loading indicator
      }
    };

    if (!hasFetched.current) {
      fetchPositions(); // Fetch positions only once
      hasFetched.current = true;
    }
  }, [accountId, onPortfolioValueSumChange, onHighValueSumChange, isDemo]);

  const getBackgroundColor = (lastPrice, high52Week) => {
    let backgroundColor = "#fff"; // Default background
    if (lastPrice && high52Week) {
      const pricePercentage = (lastPrice / high52Week) * 100;

      if (pricePercentage >= 100) {
        backgroundColor = "lightgreen"; // Green if at or above 100%
      } else if (pricePercentage >= 0) {
        // Linear interpolation for red shade between 80% and 100%
        const intensity = ((pricePercentage - 0) / 100) * 255;
        backgroundColor = `rgb(255, ${Math.floor(intensity)}, ${Math.floor(
          intensity
        )})`;
      } else {
        backgroundColor = "rgb(255, 0, 0)";
      }
    }
    return backgroundColor;
  };

  if (loading) return <p>Loading positions...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="position-table-container">
      {loading ? (
        <p>Loading positions...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : positions.length > 0 ? (
        <table className="position-table">
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Quantity</th>
              <th>Last Price</th>
              <th>Value</th>
              <th>52W High</th>
              <th>Drawdown %</th>
              <th>High Value</th>
            </tr>
          </thead>
          <tbody>
            {positions.map((position) => (
              <tr
                key={position.symbol}
                style={{
                  backgroundColor: getBackgroundColor(
                    position.lastPrice,
                    position.high52Week
                  ),
                }}
              >
                <td>{position.symbol}</td>
                <td>{position.quantity.toLocaleString()}</td>
                <td>${position.lastPrice.toFixed(2)}</td>
                <td>${position.value.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
                <td>${position.high52Week.toFixed(2)}</td>
                <td>-{position.drawdown.toFixed(2)}%</td>
                <td>${position.highValue.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No positions added for this account yet.</p>
      )}
    </div>
  );
};

export default PositionTable;
