import React from "react";
import PortfolioSummary from "./PortfolioSummary.js";
import AccountList from "./AccountList.js";
import PortfolioOverlay from "./PortfolioOverlay.js";
import "./Portfolio.css";

const Portfolio = ({
  user,
  portfolioValue,
  highValue,
  drawdown,
  highValueDifference,
  onPortfolioValueChange,
  onHighValueChange,
}) => {
  if (!user) {
    return (
      <div className="portfolio-container">
        <PortfolioOverlay />
      </div>
    );
  }

  return (
    <div className="portfolio-container">
      <PortfolioSummary
        portfolioValue={user ? portfolioValue : 0}
        highValue={user ? highValue : 0}
        drawdown={user ? drawdown : 0}
        retracement={user ? highValueDifference : 0}
      />
      <AccountList
        user={user}
        onPortfolioValueChange={onPortfolioValueChange}
        onHighValueChange={onHighValueChange}
      />
    </div>
  );
};

export default Portfolio;
