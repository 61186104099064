import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import StockDrawdown from "../components/StockDrawdown.js";
import WorldMap from "../components/WorldMap.js";

const countryETFs = {
  "United States": { ticker: "SPY" },
  "China": { ticker: "FXI" },
  "Japan": { ticker: "EWJ" },
  "United Kingdom": { ticker: "EWU" },
  "Canada": { ticker: "EWC" },
  "Germany": { ticker: "EWG" },
  "France": { ticker: "EWQ" },
  "Australia": { ticker: "EWA" },
  "Brazil": { ticker: "EWZ" },
  "South Korea": { ticker: "EWY" },
  "India": { ticker: "INDA" },
  "Mexico": { ticker: "EWW" },
  "Russia": { ticker: "RSX" },
  "South Africa": { ticker: "EZA" },
  "Italy": { ticker: "EWI" },
  "Spain": { ticker: "EWP" },
  "Taiwan": { ticker: "EWT" },
  "Netherlands": { ticker: "EWN" },
  "Sweden": { ticker: "EWD" },
  "Switzerland": { ticker: "EWL" }
};

const Indexes = () => {
  const [stockData, setStockData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCountryData = async () => {
      setLoading(true);
      try {
        const promises = Object.entries(countryETFs).map(async ([country, { ticker }]) => {
          const { data, error } = await supabase.functions.invoke(
            "get52WeekHigh",
            {
              body: JSON.stringify({ symbol: ticker }),
            }
          );

          if (error) throw error;

          const parsedData = JSON.parse(data);
          const drawdown = ((parsedData.high52Week - parsedData.lastPrice) / parsedData.high52Week) * 100;

          return [country, {
            performance: -drawdown.toFixed(2),
            ticker: ticker
          }];
        });

        const results = await Promise.all(promises);
        setStockData(Object.fromEntries(results));
      } catch (error) {
        console.error("Error fetching country data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCountryData();
  }, []);

  if (loading) {
    return <div>Loading country data...</div>;
  }

  return (
    <div className="page-container">
      <div className="page-header">
        <h1 className="h-1">US Indexes</h1>
        <div className="stock-drawdown-container">
          <StockDrawdown index="S&P500" ticker="SPY"></StockDrawdown>
          <StockDrawdown index="Nasdaq 100" ticker="QQQ"></StockDrawdown>
          <StockDrawdown index="Dow Jones" ticker="DIA"></StockDrawdown>
          <StockDrawdown index="Russell 2000" ticker="IWM"></StockDrawdown>
          <StockDrawdown index="US Growth" ticker="IUSG"></StockDrawdown>
          <StockDrawdown index="US Value " ticker="IUSV"></StockDrawdown>
          <StockDrawdown index="Equal Weight" ticker="RSP"></StockDrawdown>
          <StockDrawdown index="VIX" ticker="VXX"></StockDrawdown>
        </div>
      </div>
      <div className="page-header">
        <h1 className="h-1">International Indexes</h1>
        <WorldMap stockData={stockData} />
      </div>
    </div>
  );
};

export default Indexes;
