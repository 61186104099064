import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import DrawdownChart from './DrawdownChart';
import './StockDetail.css';
import { trackEvent } from '../utils/analytics';
import SignUp from './auth/signUp';
import afterHoursIcon from './media_assets/after_hours.svg';
import { isPreMarket } from '../utils/timeUtils';
import { Helmet } from 'react-helmet-async';
import { HelmetProvider } from 'react-helmet-async';

const StockDetail = () => {
  const { symbol } = useParams();
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState({
    name: null,
    high52Week: null,
    low52Week: null
  });
  const [priceData, setPriceData] = useState({
    regularPrice: null,
    regularPriceChange: null,
    regularPercentChange: null,
    afterHoursPrice: null,
    afterHoursPriceChange: null,
    afterHoursPercentChange: null,
    isAfterHours: false
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isWatching, setIsWatching] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch company details
        const { data: companyDetails, error: companyError } = await supabase.functions.invoke(
          'getStockDetails',
          {
            body: JSON.stringify({ symbol: symbol.toUpperCase() }),
          }
        );

        if (companyError) throw companyError;
        if (!companyDetails || !companyDetails.name) {
          setError('not_found');
          return;
        }
        setCompanyData(companyDetails);

        // Fetch current price data
        const { data: priceDetails, error: priceError } = await supabase.functions.invoke(
          'getDailyPriceChange',
          {
            body: JSON.stringify({ symbol: symbol.toUpperCase() }),
          }
        );

        if (priceError) throw priceError;
        setPriceData(priceDetails);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('not_found');
      } finally {
        setLoading(false);
      }
    };

    if (symbol) {
      fetchData();
    }
  }, [symbol]);

  useEffect(() => {
    // Track stock detail view
    trackEvent('Stock Detail', 'Detail Viewed', symbol);
  }, [symbol]);

  // Check if symbol is in watchlist
  useEffect(() => {
    const checkWatchlist = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('watchlists')
        .select()
        .eq('user_id', user.id)
        .eq('symbol', symbol.toUpperCase());

      if (!error && data.length > 0) {
        setIsWatching(true);
      }
    };

    checkWatchlist();
  }, [symbol]);

  const handleWatchlistClick = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    
    if (!user) {
      setShowSignUp(true);
      return;
    }

    if (isWatching) {
      // Delete the watchlist entry
      const { error } = await supabase
        .from('watchlists')
        .delete()
        .eq('user_id', user.id)
        .eq('symbol', symbol.toUpperCase());

      if (!error) {
        setIsWatching(false);
      } else {
        console.error('Error removing from watchlist:', error);
      }
    } else {
      // Add to watchlist
      const { error } = await supabase
        .from('watchlists')
        .insert([
          { 
            user_id: user.id, 
            symbol: symbol.toUpperCase(),
            created_at: new Date().toISOString()
          }
        ]);

      if (!error) {
        setIsWatching(true);
      }
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error === 'not_found') {
    return (
      <div className="symbol-not-found">
        <h1>Symbol Not Found</h1>
        <p>Sorry, we couldn't find any data for "{symbol}"</p>
        <button 
          onClick={() => navigate('/')} 
          className="home-link"
        >
          Return to Home
        </button>
      </div>
    );
  }

  const getPriceChangeColor = (change) => {
    return change >= 0 ? 'var(--success)' : 'var(--error)';
  };

  return (
    <HelmetProvider>
      <div className="stock-detail">
        <Helmet>
          <title>{`${symbol} Stock Drawdown Analysis | Current Price & 52-Week High | StockDrawdowns`}</title>
          <meta name="description" content={`Real-time ${companyData?.name} (${symbol}) stock analysis. Current price: $${priceData?.regularPrice?.toFixed(2)}. Drawdown from 52-week high: ${((companyData?.high52Week / priceData?.regularPrice - 1) * 100).toFixed(2)}%. Track pre-market and after-hours trading.`} />
          <meta name="keywords" content={`${symbol} stock, ${symbol} drawdown, ${companyData?.name} stock price, stock analysis, market drawdown, 52 week high, stock tracking`} />
          
          {/* Open Graph tags */}
          <meta property="og:title" content={`${symbol} Stock Drawdown Analysis | StockDrawdowns`} />
          <meta property="og:description" content={`${companyData?.name} (${symbol}) is currently $${priceData?.regularPrice?.toFixed(2)} with a ${((companyData?.high52Week / priceData?.regularPrice - 1) * 100).toFixed(2)}% drawdown from its 52-week high of $${companyData?.high52Week?.toFixed(2)}.`} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`https://stockdrawdowns.com/stocks/${symbol}`} />
          <meta property="og:site_name" content="StockDrawdowns" />
          
          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${symbol} Stock Drawdown Analysis`} />
          <meta name="twitter:description" content={`Track ${companyData?.name} (${symbol}) stock performance and drawdown analysis in real-time.`} />
          
          {/* Additional SEO tags */}
          <link rel="canonical" href={`https://stockdrawdowns.com/stocks/${symbol}`} />
        </Helmet>
        <div className="company-header">
          <div className="name-symbol">
            <h1>
              {companyData?.name} <span className="symbol-spacer">({symbol})</span>
            </h1>
          </div>
          <div className="price-info">
            <div className="price-group">
              <span className="price-label">
                {priceData?.isAfterHours ? 'Close' : ''}
              </span>
              <div className="price-details">
                <span className="current-price">
                  ${priceData?.regularPrice ? priceData.regularPrice.toFixed(2) : '—'}
                </span>
                {priceData?.regularPriceChange != null && priceData?.regularPercentChange != null && (
                  <span 
                    className="price-change"
                    style={{ color: getPriceChangeColor(priceData.regularPriceChange) }}
                  >
                    {priceData.regularPriceChange >= 0 ? '+' : ''}
                    {priceData.regularPriceChange.toFixed(2)} ({priceData.regularPercentChange.toFixed(2)}%)
                  </span>
                )}
              </div>
            </div>
            {priceData?.isAfterHours && priceData?.afterHoursPrice && (
              <div className="price-group after-hours">
                <div className="after-hours-label">
                  <span className="price-label">
                    {isPreMarket() ? 'Pre-Market' : 'After Hours'}
                  </span>
                  <img src={afterHoursIcon} alt="After Hours" className="after-hours-icon" />
                </div>
                <div className="price-details">
                  <span className="after-hours-price">
                    ${priceData.afterHoursPrice.toFixed(2)}
                  </span>
                  {priceData?.afterHoursPriceChange != null && priceData?.afterHoursPercentChange != null && (
                    <span 
                      className="after-hours-change"
                      style={{ color: getPriceChangeColor(priceData.afterHoursPriceChange) }}
                    >
                      {priceData.afterHoursPriceChange >= 0 ? '+' : ''}
                      {priceData.afterHoursPriceChange.toFixed(2)} ({priceData.afterHoursPercentChange.toFixed(2)}%)
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="stock-actions">
          <button 
            onClick={handleWatchlistClick}
            className="watchlist-button"
            title={isWatching ? "Remove from Watchlist" : "Add to Watchlist"}
          >
            {isWatching ? "Remove from Watchlist" : "+ Add to Watchlist"}
          </button>
        </div>
        {showSignUp && <SignUp onClose={() => setShowSignUp(false)} />}
        <DrawdownChart symbol={symbol} />
        <div className="drawdown-summary">
          <div className="metric-row">
            <div className="metric">
              <span className="label">52-Week Range</span>
              <span className="value">
                ${companyData?.low52Week?.toFixed(2)} - ${companyData?.high52Week?.toFixed(2)}
              </span>
            </div>
            <div className="metric">
              <span className="label">Retracement % to 52-Week High</span>
              <span className="value">
                {companyData?.high52Week && priceData?.regularPrice
                  ? ((companyData.high52Week / priceData.regularPrice - 1) * 100).toFixed(2)
                  : '—'}%
              </span>
            </div>
          </div>
        </div>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FinancialProduct",
            "name": `${companyData?.name} Stock`,
            "ticker": symbol,
            "price": priceData?.regularPrice,
            "description": `${companyData?.name} (${symbol}) stock price, drawdown analysis, and market performance tracking.`,
            "category": "Stock",
            "url": `https://stockdrawdowns.com/stocks/${symbol}`,
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": `https://stockdrawdowns.com/stocks/${symbol}`
            },
            "breadcrumb": {
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "item": {
                    "@id": "https://stockdrawdowns.com",
                    "name": "Home"
                  }
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "item": {
                    "@id": "https://stockdrawdowns.com/stocks",
                    "name": "Stocks"
                  }
                },
                {
                  "@type": "ListItem",
                  "position": 3,
                  "name": `${companyData?.name} (${symbol})`
                }
              ]
            }
          })}
        </script>
      </div>
    </HelmetProvider>
  );
};

export default StockDetail; 