import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="frame">
      {/* <div className="join_investors">
        <p>
          This website is for informational purposes only. Contact{" "}
          <a 
            href="https://advisor.ml.com/sites/ks/leawood-ks/vinsant-wealth-management"
            target="_blank"
            rel="noopener noreferrer"
            className="external-link"
          >
            Vinsant Wealth Management
          </a>{" "}
          if you are seeking financial advice.
        </p>
      </div> */}
      <div className="join_investors">
        <p>
          <a href="/privacy-policy">Privacy policy</a> | &copy; 2025 by{" "}
          <a 
            href="https://www.linkedin.com/in/aris-vinsant-960037138/"
            target="_blank"
            rel="noopener noreferrer"
            className="external-link"
          >
            Aris Vinsant
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;