export const isPreMarket = () => {
  const now = new Date();
  const easternTime = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));
  const hours = easternTime.getHours();
  const minutes = easternTime.getMinutes();
  
  // Convert to minutes since midnight
  const currentMinutes = hours * 60 + minutes;
  const marketOpen = 9 * 60 + 30;  // 9:30 AM ET
  
  return currentMinutes < marketOpen;
};

export const isAfterHours = () => {
  const now = new Date();
  const easternTime = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));
  const day = easternTime.getDay();
  const hours = easternTime.getHours();
  const minutes = easternTime.getMinutes();
  
  // Weekend check (Saturday = 6, Sunday = 0)
  if (day === 6 || day === 0) {
    return true;
  }

  // Convert to minutes since midnight
  const currentMinutes = hours * 60 + minutes;
  const marketOpen = 9 * 60 + 30;  // 9:30 AM ET
  const marketClose = 16 * 60;     // 4:00 PM ET

  const isAfterHoursTime = currentMinutes < marketOpen || currentMinutes >= marketClose;
  
  console.log({
    easternTime: easternTime.toLocaleString(),
    currentMinutes,
    marketOpen,
    marketClose,
    isAfterHoursTime
  });

  return isAfterHoursTime;
};