import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from 'react-router-dom';
import "./Home.css";
import StockDrawdown from "../components/StockDrawdown.js";
import Portfolio from "../components/portfolio/Portfolio.js";
import WatchlistOverlay from "../components/watchlist/WatchlistOverlay.js";

const Home = () => {
  const [user, setUser] = useState(null);
  const [portfolioValue, setPortfolioValue] = useState(0);
  const [highValue, setHighValue] = useState(0);
  const [symbol, setSymbol] = useState('');
  const [loading, setLoading] = useState(true);
  const [watchlist, setWatchlist] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const { data: { user } } = await supabase.auth.getUser();
        setUser(user);
        
        if (user) {
          const { data, error } = await supabase
            .from('watchlists')
            .select('symbol')
            .eq('user_id', user.id);

          if (error) {
            console.error('Error fetching watchlist:', error);
            return;
          }
          
          if (data) {
            setWatchlist(data.map(item => item.symbol));
          }
        }
      } catch (error) {
        console.error('Error in fetchUser:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (symbol.trim()) {
      const formattedSymbol = symbol.trim().toUpperCase();
      navigate(`/stocks/${formattedSymbol}`);
    }
  };

  const handlePortfolioValueChange = (sum) => {
    setPortfolioValue(sum.toFixed(2));
  };

  const handleHighValueChange = (sum) => {
    setHighValue(sum.toFixed(2));
  };

  const drawdown =
    highValue > 0 ? ((1 - portfolioValue / highValue) * 100).toFixed(2) : 0;

  const highValueDifference =
    highValue > 0 ? (highValue - portfolioValue).toFixed(2) : 0;

  return (
    <div className="home">
      <div className="index-drawdowns">
        <div className="page-header">
          <h1 className="h-1">Home</h1>
        </div>
        <div className="stock-drawdown-container">
          <StockDrawdown index="S&P500" ticker="SPY"></StockDrawdown>
          <StockDrawdown index="Nasdaq 100" ticker="QQQ"></StockDrawdown>
          <StockDrawdown index="Dow Jones" ticker="DIA"></StockDrawdown>
          <StockDrawdown index="Russell 2000" ticker="IWM"></StockDrawdown>
        </div>
        <form className="home-search-wrapper" onSubmit={handleSubmit}>
          <svg 
            className="home-search-icon" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
          <input 
            type="text" 
            placeholder="Search for any stock or ETF..." 
            className="home-search-input"
            value={symbol}
            onChange={(e) => setSymbol(e.target.value)}
          />
        </form>
      </div>
      <div className="page-header">
        <div className="header-with-link">
          <h1 className="h-1">Watchlist</h1>
          {user && (
            <a href="/watchlist" className="watchlist-link">
              Full Watchlist
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
                className="arrow-icon"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </a>
          )}
        </div>
        {!user ? (
          <WatchlistOverlay />
        ) : loading ? (
          <p className="status-message">Loading watchlist...</p>
        ) : watchlist.length > 0 ? (
          <div className="stock-drawdown-container">
            {watchlist.map(symbol => (
              <StockDrawdown 
                key={symbol} 
                index={symbol} 
                ticker={symbol}
              />
            ))}
          </div>
        ) : (
          <p className="status-message">You don't have any watchlisted symbols yet.</p>
        )}
      </div>
     
      <div className="my-portfolio">
        <div className="header-with-link">
          <h1 className="h-1">My Portfolio</h1>
          {user && (
            <a href="/portfolio" className="watchlist-link">
              Full Portfolio
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
                className="arrow-icon"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </a>
          )}
        </div>

          <Portfolio
            user={user}
            portfolioValue={portfolioValue}
            highValue={highValue}
            drawdown={drawdown}
            highValueDifference={highValueDifference}
            onPortfolioValueChange={handlePortfolioValueChange}
            onHighValueChange={handleHighValueChange}
          />

      </div>
    </div>
  );
};

export default Home;
