import React from "react";
import { supabase } from "../../supabaseClient";
import "./AddAccount.css";

const AddAccount = ({ user, onSignUpClick }) => {
  const handleAddAccount = async () => {
    if (!user) {
      onSignUpClick();
      return;
    }
    
    try {
      const { error } = await supabase.from("accounts").insert([
        {
          user_id: user.id,
          name: `My Account #${Math.floor(Math.random() * 1000)}`,
        },
      ]);

      if (error) throw error;
      window.location.reload();
    } catch (error) {
      console.error("Error adding account:", error);
    }
  };

  return (
    <button 
      className="add-account-button" 
      onClick={handleAddAccount}
      disabled={false}
    >
      + Add Account
    </button>
  );
};

export default AddAccount;
