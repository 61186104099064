import React, { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import { Tooltip } from "react-tooltip";

const geoUrl = "/countries-110m.json";

const WorldMap = ({ stockData }) => {
  const [tooltipId] = useState("country-tooltip");

  // Example color scale based on stock performance
  const colorScale = scaleLinear()
    .domain([-10, 0]) // Example range: -10% to +10% performance
    .range(["#FF0000", "#ffffff"]); // Red to Yellow to Green

  // Helper function to get country performance
  const getCountryData = (countryName) => {
    return stockData[countryName] || { performance: "N/A", ticker: "" };
  };

  return (
    <>
      <ComposableMap
        projection="geoMercator"
        width={500}
        height={300}
        projectionConfig={{
          scale: 100,
          center: [10, 30] // Adjust the vertical center to show more of the northern hemisphere
        }}
        data-tooltip-id={tooltipId}
      >
        <path
          d="M0,0 v300 h500 v-300 z"
          fill="none"
          stroke="rgba(0,0,0,0.8)"
          strokeWidth={1}
        />
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const countryName = geo.properties.name;
              const { performance, ticker } = getCountryData(countryName);

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  style={{
                    default: {
                      outline: "none",
                      stroke: "#000", // Black outline
                      strokeWidth: 0.25, // Thin outline
                    },
                    hover: { fill: "#f53", outline: "none" },
                    pressed: { outline: "none" },
                  }}
                  fill={
                    performance !== "N/A" ? colorScale(performance) : "#000"
                  }
                  data-tooltip-id={tooltipId}
                  data-tooltip-content={`${countryName || "Unknown"} (${
                    ticker || "No Ticker"
                  }): ${
                    performance !== "N/A"
                      ? `${performance}%`
                      : "No Data Available"
                  }`}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
      <Tooltip id={tooltipId} />
    </>
  );
};

export default WorldMap;
